





























































































































































































































import Vue from 'vue';
import Scaffold from '@/components/Presenter/Scaffold.vue';
import ShareModal from '@/components/Presenter/ShareModal.vue';
import ThemeSelect from '@/components/Presenter/ThemeSelect.vue';
import { mapGetters } from 'vuex';
import { HollowDotsSpinner } from 'epic-spinners';

export default Vue.extend({
  components: { Scaffold, ShareModal, HollowDotsSpinner, ThemeSelect },
  data() {
    return {
      form: {
        name: '',
        description: '',
        startAt: new Date(),
        sessionUnlimited: false,
        // current date + 24hours
        endAt: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
        theme: 'reaction.link',
        // link: '',
      },
      loading: false,
      now: new Date(),
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      shareURL: 'presenter/getShareURL',
      shareToken: 'presenter/getSessionShareToken',
      sessionThemes: 'presenter/getSessionThemes',
      isSuperUser: 'auth/isSuperUser',
    }),
    isFormSubmitable(): boolean {
      return (
        this.form.name != '' &&
        this.form.startAt != this.now &&
        this.form.endAt != this.now
      );
    },
  },
  methods: {
    async submitForm() {
      this.loading = true;
      await this.$store.dispatch('presenter/createSession', {
        form: this.form,
        callback: this.callback,
      });
    },
    callback() {
      this.loading = false;
      this.showSuccessModal = true;
    },
  },
});
