






















import Vue from 'vue';
export default Vue.extend({
  props: {
    themes: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: 'reaction.link',
    },
  },
  computed: {
    selectedOption: {
      get(): string {
        return this.value;
      },
      set(val: string): void {
        this.$emit('input', val);
      },
    },
  },
});
